import React, { useState } from "react";
import './OnlineIcon.css';
import OnlineIconOne from "../../images/AnalysisIcon.webp";
import OnlineIconTwo from "../../images/AcademyIcon.webp";
import Analysis from './Analysis';
import Academy from './Academy';

const OnlineIcon = () => {

    const [OnlineOne, setOnlineOne] = useState("block");
    const [OnlineTwo, setOnlineTwo] = useState("none");

    function handlerOnlineOne(){
        setOnlineOne("block");
        setOnlineTwo("none");
    }

    function handlerOnlineTwo(){
        setOnlineOne("none");
        setOnlineTwo("block");
    }

    return (
        <div>
            <div className="Online-Links">
                <div className="Online-Icon">                    
                    <img src={OnlineIconOne} onClick={handlerOnlineOne} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: OnlineOne }}></div>
                </div>
                <div className="Online-Icon">
                    <img src={OnlineIconTwo} onClick={handlerOnlineTwo} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: OnlineTwo }}></div>
                </div>                
            </div>
            <div className="boxes-Online">
                <Analysis display={OnlineOne}/>   
                <Academy display={OnlineTwo}/>                         
            </div>
        </div>
    );
}

export default OnlineIcon; 