import './OtherTour.css';
import othertourOne from '../../webp/otherNewOne.webp';
import othertourTwo from '../../webp/otherNewTwo.webp';



const OtherTour = (props) => {

    var mobileLastppa =
    <div className='ribbonsOtherNew'>
        <div className='otherImage'>
            <img src={othertourTwo} className="mainTourImage" />
        </div>
        <div className='newSmallOther'>
            <div className='newSmallTitles'>
                <div className='smallP'><h1>Other Medals:</h1></div>
                <div className='smallH1'><h1 className='totalMedals'>{props.otherMedals}</h1></div>
            </div>
        </div>
    </div>;

var NotmobileLastppa =
    <div className='ribbonsOtherNew'>
        <div className='newSmallOther'>
            <div className='newSmallTitles'>
                <div className='smallP'><h1>Other Medals:</h1></div>
                <div className='smallH1'><h1 className='totalMedals'>{props.otherMedals}</h1></div>
            </div>
        </div>
        <div className='otherImage'>
            <img src={othertourTwo} className="mainTourImage" />
        </div>
    </div>;

    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>OTHER TOURNAMENTS</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='otherTourBox'>
                    <p className='otherTourPara'>Prior to signing with the PPA, Callie played in APP, USA Pickleball, and a variety of other tournaments all around the country.</p>
                </div>                 
                <div className='otherTourNew'>
                    <div className='ribbonImage'>
                        <img src={othertourOne} className="mainTourImage" />
                    </div>
                    <div className='textOtherNew'>
                        <div className='titlesOtherNew'>
                            <div className='otherNewP'><h1>Other Titles:</h1></div>
                            <div className='otherNewH1'><h1 className='totalMedals'>{props.otherTitles}</h1></div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 600 ?  NotmobileLastppa : mobileLastppa}
            </div> 
            <br />      
        </div>
    )
}

export default OtherTour;