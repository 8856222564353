import './SponsorsIcons.css';
import React, { useState } from "react";
import SponIconOne from "../../images/LinkTree_Two.webp";
import SponIconSeven from "../../images/BZER.webp";
import SponIconEight from "../../images/theKitchenIcon.png";
import LinkTree from './LinkTree';
import TheKitchen from './TheKitchen' ;
import Bzer from './Bzer';


const PartnershipIcons = () => {

    const [SponOne, setSponOne] = useState("block");
    const [SponEight, setSponEight] = useState("none");
    const [SponSeven, setSponSeven] = useState("none");
    
 

    function handlerSponOne(){
        setSponOne('block');
        setSponEight('none');
        setSponSeven('none');
    }

    function handlerSponSeven(){
        setSponOne('none');
        setSponSeven('block');
        setSponEight('none');

    }

    function handlerSponEight(){
        setSponOne('none');
        setSponSeven('none');
        setSponEight('block');

    }



    var SponsDesktop = 
        <div>
            <div className="Spon-Links">
                <div className="Spon-Icon">                    
                    <img src={SponIconOne} onClick={handlerSponOne} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponOne }}></div>
                </div>
                <div className="Spon-Icon">
                    <img src={SponIconEight} onClick={handlerSponEight} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponEight }}></div>
                </div> 
                <div className="Spon-Icon">
                    <img src={SponIconSeven} onClick={handlerSponSeven} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponSeven }}></div>
                </div> 
                        
            </div>
            <div className="boxes-Spon">
                <LinkTree display={SponOne}/>
                <TheKitchen display={SponEight} />
                <Bzer display={SponSeven} />
                
                
                
            </div>
            <br />
            <br />
        </div>;

    var SponsMobile = 
        <div>
            <div className="Spon-Links">
                    <div className="Spon-Icon">                    
                        <img src={SponIconOne} onClick={handlerSponOne} style={{ cursor: "pointer" }}/>
                        <div className="triangle" style={{ display: SponOne }}></div>
                    </div>
                    {/*<div className="Spon-Icon">
                        <img src={SponIconTwo} onClick={handlerSponTwo} style={{ cursor: "pointer" }}/>
                        <div className="triangle" style={{ display: SponTwo }}></div>
                    </div>
                */}
                    <div className="Spon-Icon">
                        <img src={SponIconEight} onClick={handlerSponEight} style={{ cursor: "pointer" }}/>
                        <div className="triangle" style={{ display: SponEight }}></div>
                    </div>
            </div>
            <div className="boxes-Spon">
                <LinkTree display={SponOne}/>
                <TheKitchen display={SponEight} />
                 {/*<Onix display={SponTwo}/>*/}
            </div>
            <div className="Spon-Links">
                
            <div className="Spon-Icon ">
                    <img src={SponIconSeven} onClick={handlerSponSeven} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponSeven }}></div>
                </div>  
                
            </div>
            <div className="boxes-Spon">
                <Bzer display={SponSeven} />
                
            </div>
           
            <br />
            <br />
        </div>


return (
    <div>
        {window.innerWidth > 600 ?  SponsDesktop : SponsMobile}
    </div>    
)
}

export default PartnershipIcons;