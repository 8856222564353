import './Sponsors.css';
import PartnershipIcons from './PartnershipIcons';

const Partnerships = () => {
    return (
        <div>
            <div className="gradient-bar"></div>
            <div className='page-Headers'><h1>CALLIE'S Partners</h1></div>
            <PartnershipIcons />
        </div>
        
    )
}

export default Partnerships; 