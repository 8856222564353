import './Icons.css';
import React, { useState } from 'react';
import icon1 from "../../webp/coachIconNew.webp";
import icon2 from '../../webp/followIconNew.webp';
import icon3 from '../../webp/tourIconNew.webp';
import icon4 from '../../webp/shopIconNew.webp';
import Clinics from "./Clinics";
import Shop from "./Shop";
import Follow from "./Follow";
import Tour from "./Tour";

const Icons = () => {

    const [tri_one, setOne] = useState("block");
    const [tri_two, setTwo] = useState("none");
    const [tri_three, setThree] = useState("none");
    const [tri_four, setfour] = useState("none");


    function boxOneHandler(){
        setOne("block");
        setTwo("none");
        setThree("none");
        setfour("none");
    }

    function boxTwoHandler(){
        setOne("none");
        setTwo("block");
        setThree("none");
        setfour("none");
    }

    function boxThreeHandler(){
        setOne("none");
        setTwo("none");
        setThree("block");
        setfour("none");
    }

    function boxFourHandler(){
        setOne("none");
        setTwo("none");
        setThree("none");
        setfour("block");
    }
    return (
        <div>
            <div className="Icon-Links">
                <div className="Icon">                    
                    <img src={icon1} onClick={boxOneHandler} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: tri_one }}></div>
                </div>
                <div className="Icon">
                    <img src={icon2} onClick={boxTwoHandler} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: tri_two }}></div>
                </div>
                <div className="Icon">
                    <img src={icon3} onClick={boxThreeHandler} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: tri_three }}></div>
                </div>
                <div className="Icon">
                    <img src={icon4} onClick={boxFourHandler} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: tri_four }}></div>
                </div>
            </div>
            <div className="boxes-I">
               <Clinics display={tri_one}/>
               <Follow display={tri_two}/>
               <Tour display={tri_three}/>
               <Shop display={tri_four} />
               
            </div>

        </div>

    )
}

export default Icons