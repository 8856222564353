import './AloeMd.css';


const TheKitchen = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>THE KITCHEN INDOOR PICKLEBALL COURTS</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='onix-box'>
                    <h2>The Kitchen provides a variety of opportunities for players to get involved in pickleball. Whether you want to franchise a Kitchen Facility, participate in clinics and leagues with players near your level, or just simply enjoy playing with your friends - The Kitchen has something for you!</h2>
                    <div className='onix-button'>
                    {/*<a href="https://www.aloemd.com/?ref=0NOcKe1Psbc64-" target="_blank" ><button className='clinic_button'>Shop AloeMD Products</button></a>*/}
                    <a href="https://www.thekitchenpb.com/callie-jo-smith-membership" target="_blank"><button className='clinic_button'>Join Callie's Kitchen</button></a>
                    <a href="https://www.thekitchenpb.com/franchise" target="_blank"><button className='clinic_button'>Franchise with the Kitchen</button></a>
                    </div>                    
                </div>                         
            </div> 
            <br />      
        </div>
    )
}

export default TheKitchen;