import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Nav from './components/Nav';
import Home from './components/Home/Home';
import Coaching from './components/Coaching/Coaching';
import OnlineInstruction from './components/Coaching/OnlineInstruction';
import CalliesKitchen from './components/Follow Callie/CalliesKitchen';
import LearnAboutCallie from './components/Follow Callie/LearnAboutCallie';
import SocialMedia from './components/Follow Callie/SocialMedia';
import Performance from './components/Pro Tour/Performance';
import Sponsors from './components/Pro Tour/Sponsors';
import Partnerships from './components/Pro Tour/Partnerships';
import TourSchedule from './components/Pro Tour/TourSchedule';
import Career from './components/Pro Tour/Career';
import AOS from "aos";
import "aos/dist/aos.css";






function App() {


  useEffect(() => {
		AOS.init({
			once: true,
			duration: 800,
			offset: 200,
		});
	}, []);

  return (
    <div>
      <Nav />
      <Routes>        
        <Route path="index.html" element={ <Home />} />
        <Route path="Coaching" element={<Coaching />} />
        <Route path="Online" element={<OnlineInstruction />} />
        <Route path="Kitchen" element={<CalliesKitchen />}/>
        <Route path="LearnAbout" element={<LearnAboutCallie />}/>
        <Route path="SocialMedia" element={<SocialMedia />}/>
        <Route path="Sponsors" element={<Sponsors />}/>
        <Route path="Performance" element={<Performance/>}/>
        <Route path="Partnerships" element={<Partnerships />}/>
        <Route path="TourSchedule" element={<TourSchedule />}/>
        <Route path="Career" element={<Career />}/>
        <Route path="/" element={ <Home />} />
      </Routes>
    </div>  
  );
}

export default App;
