import Icons from './Icons';
import Slider from './Slider';

function Home() {
  return (
    <div>
      <div className="gradient-bar"></div>
      <Slider />
      <Icons />
    </div>  
  );
}

export default Home;