import './Sponsors.css';
import SponsorsIcons from './SponsorsIcons';

const Sponsors = () => {
    return (
        <div>
            <div className="gradient-bar"></div>
            <div className='page-Headers'><h1>CALLIE'S SPONSORS</h1></div>
            <SponsorsIcons />
        </div>
        
    )
}

export default Sponsors; 