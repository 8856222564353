import './LinkTree.css';
import Emailmodal_Linktree from "../Emailmodal_Linktree.js";
import React, { useState } from "react";

const LinkTree = (props) => {

    const [modalState_link, changeModal_link] = useState("none");

    function Listener_Modal_link() {
        if (modalState_link == "none") {
            changeModal_link("block");
        } else {
            changeModal_link("none");
        }
    }

    function closedModal_link(event) {
        if (event == document.querySelector('.modal-Link')) {
            changeModal_link("none");
        }
    }


    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>LINK TREE</h1>
            </div>
            <div className='kitchen-bg'>
                      <div className='tree-boxes'>
                          <div className='tree-box'>
                            <h2>Take advantage of Callie's Discounts TODAY!</h2>
                            <br />
                            <div className='tree-button' style={{textAlign : "center"}}>
                                <a href="https://linktr.ee/Calliejosmith" target="_blank" ><button className='clinic_button'>Callie's Sponsor Discounts</button></a>
                            </div>
                          </div>
                          <div className='tree-box'>
                            <h2>Want to Become a Sponsor?</h2>
                            <br />
                            <div className='tree-button' style={{textAlign : "center"}}>
                                <button onClick={Listener_Modal_link} className='clinic_button'>Become a Sponsor</button>
                                <Emailmodal_Linktree callevent={closedModal_link} display={modalState_link} showmodal={Listener_Modal_link} />
                            </div>
                          </div>
                      </div>
            </div> 
            <br />      
        </div>
    )
}

export default LinkTree;