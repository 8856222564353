
import './WhySub.css';
import subImage from '../../webp/WhyKImage.webp';
import KitchenTable from './KitchenTable';

const WhySub = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>WHY SUBSCRIBE?</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='sub-background'>
                    <div className='sub-box'>
                        <h3>Why do I need Callie's newsletter?</h3>
                        <p>Remove the filter and get behind the scenes access to a professional athlete who is also a mother, a wife, a singer, and so much more.</p>
                        <h3>Are <b>YOU</b> ready</h3>
                        <a href="https://view.flodesk.com/pages/619c7f6148c53bc0f78e5f46"><button className='clinic_button'>Subscribe Today!</button></a>
                    </div>
                    <div className='sub-box'>
                        <img src={subImage} className='subImageOne' />
                    </div>
                </div>
                <br />                      
                <KitchenTable />      
            </div>             
            <br /> 
            <br />      
        </div>
    )
}

export default WhySub;