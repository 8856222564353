import './AboutKyleCallie.css';
import kyleOneImg from '../../images/Kyle_Callie_One.webp';
import kyleTwoImg from '../../images/Kyle_Callie_Two.webp';
import insta from "../../images/Insta_Icon.webp";
import facebook from "../../images/FaceBook_Icon.webp";
import tiktok from "../../images/TikTok_Icon.webp";
import linktree from "../../images/LinkTree_Icon.webp";

var kyleMobile =
    <div className='kyle-boxOne'>
        <div className='image-kyle-box'>
            <img src={kyleOneImg} className='kyle-images' />
        </div>
        <br />

        <div className='story-box'>
            <h3>Their Story</h3>
            <p>Callie met the love of her life, Kyle, after being
                set up on a blind date. Their initial plan was to play tennis. However, Kyle wisely changed the plan to a picnic upon learning that Callie played tennis for the University of Utah. They
                offcially began dating in August 2013 and
                were engaged a year later.</p>
        </div>
    </div>

var kyleNotMobile = <div className='kyle-boxOne'>
    <div className='story-box'>
        <h3>Their Story</h3>
        <p>Callie met the love of her life, Kyle, after being
            set up on a blind date. Their initial plan was to play tennis. However, Kyle wisely changed the plan to a picnic upon learning that Callie played tennis for the University of Utah. They
            offcially began dating in August 2013 and
            were engaged a year later.</p>
    </div>
    <br />
    <div className='image-kyle-box'>
        <img src={kyleOneImg} className='kyle-images' />
    </div>
</div>


const AboutKyleCallie = (props) => {
    return (
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>CALLIE & KYLE</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='kyle-boxes'>
                    {window.innerWidth > 1000 ? kyleNotMobile : kyleMobile}
                    <div className='kyle-boxTwo'>
                        <div className='image-kyle-box'>
                            <img src={kyleTwoImg} className='kyle-images' />
                        </div>
                        <br />
                        <div className='storyTwo-box'>
                            <p>Callie and Kyle were sealed in the Salt Lake
                                Temple (The Church of Jesus Christ of Latter
                                -Day Saints) on December 30, 2014 and
                                have lived in Utah ever since. Their first year
                                of marriage was spent finishing college at the
                                University of Utah - Callie receiving a Bachelor's Degree in Exercise Science with a
                                Minor in Nutrition and Kyle receiving a Bachelor's Degree & Certificate in Accounting.
                            </p>
                            <p>While Callie and Kyle are very busy and constantly have things going on, the most important things in their lives are each other, their faith,
                                and their family.</p>
                        </div>
                    </div>
                </div>
                <br />
                <div className='LearnMoreAbout'>
                    <h3>Want to Learn More?</h3>
                </div>
                <div className='follow-bottom'>
                    <p className='social-p'>Follow Callie on social media for quick coaching tips that will help you improve your pickleball game!</p>
                    <div className='social-d'>
                        <div>
                            <a target="_blank" href="https://www.tiktok.com/t/ZTRNvpEEq/"><img src={tiktok} className='social-button' /></a>
                        </div>
                        <div>
                            <a target="_blank" href="https://www.facebook.com/calliejo.smith.58"><img src={facebook} className='social-button' /></a>
                        </div>
                        <div>
                            <a target="_blank" href="https://www.instagram.com/calliejosmith_pickleball/?hl=en"><img src={insta} className='social-button' /></a>
                        </div>
                        <div>
                            <a target="_blank" href="https://linktr.ee/Calliejosmith"><img src={linktree} className='social-button' /></a>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default AboutKyleCallie;