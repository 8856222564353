import './CalliesKitchen.css';
import KitchenIcons from './KitchenIcons'

const CalliesKitchen = () => {
    return (
        <div>
            <div className="gradient-bar"></div>
            <div className='page-Headers'><h1>CALLIE'S KITCHEN</h1></div>
            <KitchenIcons />
        </div>
        
    )
}

export default CalliesKitchen; 