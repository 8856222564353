import './SneakPeak.css';
import whyImage from '../../images/NewsLetterExample.webp';

const SneakPeak = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>SNEAK PEAK</h1>
            </div>
            <div className='kitchen-bg'>
                     <img src={whyImage} style={{width : "100%"}}/>
            </div> 
            <br />      
        </div>
    )
}

export default SneakPeak;