
import './Mobile.css';
import MobileItems from "./MobileItems";
import React, { useState } from "react";
import Emailmodal from "./Emailmodal_mobile.js";

const  Mobile = (props) => {
/*{"link":"/Online",  "name": "Online Instruction"}*/
let itemOne = [{"link": "/Coaching", "name": "Clinics with Callie"},{"link": "/", "name": "Destination Camps"}];
let itemTwo = [{"link": "/Kitchen", "name": "Callie's Newsletter"}, {"link":"/LearnAbout",  "name": "Learn About Callie"},{"link": "/SocialMedia", "name" : "Callie's Social Media"}];
let itemThree  = [{"link":"/TourSchedule",  "name": "Tour Schedule"},{"link":"/Career",  "name": "Tour Performance"},{"name": "PPA TOUR"},{"name": "MLP"}];
let itemFour  = [{"link": "/Sponsors", "name": "Callie's Sponsors"}, {"link":"/Partnerships",  "name": "Callie's Partnerships"}];


const [navItemOne, navOneHandler] = useState(0);
const [navItemTwo, navTwoHandler] = useState(0); 
const [navItemThree, navThreeHandler] = useState(0);
const [navItemFour, navFourHandler] = useState(0);

const [modalState_mobile, changeModal_mobile] = useState("none");

function navOne(){ 
    navTwoHandler(0);
    navThreeHandler(0); 
    navFourHandler(0);
    if(navItemOne === 0){
        navOneHandler(800);
    } else {
        navOneHandler(0);
    }    
}

function navTwo(){
    navOneHandler(0);
    navThreeHandler(0);
    navFourHandler(0);
    if(navItemTwo === 0){
        navTwoHandler(800);
    } else {
        navTwoHandler(0);
    }
}

function navThree(){
    navOneHandler(0);
    navTwoHandler(0);
    navFourHandler(0);
    if(navItemThree === 0){
        navThreeHandler(800);
    } else {
        navThreeHandler(0);
    }
}

function navFour(){
    navOneHandler(0);
    navTwoHandler(0);
    navThreeHandler(0);
    if(navItemFour === 0){
        navFourHandler(800);
    } else {
        navFourHandler(0);
    }
}

function Listener_Modal_mobile() {
    if (modalState_mobile == "none") {
        changeModal_mobile("block");
    } else {
        changeModal_mobile("none");
    }
}

function closedModal_mobile(event) {
    if (event == document.querySelector('.modal-Mobile')) {
        changeModal_mobile("none");
    }
}



return(
<div>
<nav className='dropDownMobile' id='dropDownMobile'>  
    <MobileItems key="Coaching" title="Coaching" id="CoachingMobile" items={itemOne} height={navItemOne} showNav={navOne} closeNav={props.nextMobile}/>
    <MobileItems key="Follow" title="Follow Callie" id="FollowMobile" items={itemTwo} height={navItemTwo} showNav={navTwo} closeNav={props.nextMobile}/>                     
    <MobileItems key="Tour" title="Pro Tour" id="ProTour" items={itemThree} height={navItemThree} showNav={navThree} closeNav={props.nextMobile}/>
    <MobileItems key="Sponsor" title="Sponsors" id="Sponsors" items={itemFour} height={navItemFour} showNav={navFour} closeNav={props.nextMobile}/>
    <li className="nav-item"><a onClick={Listener_Modal_mobile} className="nav-link">Contact Us</a></li>
    <a onClick={() => props.nextMobile()} href="https://calliejosports.com/" target="_blank"><li>View Store</li></a>
</nav>
<Emailmodal callevent={closedModal_mobile} display={modalState_mobile} showmodal={Listener_Modal_mobile} />

</div>
    
)

}

export default Mobile