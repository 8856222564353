import './KitchenIcons.css';
import React, { useState } from "react";
import KitchenIconOne from "../../images/WhatIsItIcon.webp";
import KitchenIconTwo from "../../images/WhySubIcon.webp";
import KitchenIconThree from "../../images/SneakPeakIcon.webp";
import WhatIs from './WhatIs';
import WhySub from './WhySub';
import SneakPeak from './SneakPeak';

const KitchenIcons = () => {

    const [kitchenOne, setKitchenOne] = useState("block");
    const [kitchenTwo, setKitchenTwo] = useState("none");
    const [kitchenThree, setKitchenThree] = useState("none");

    function handlerKitchenOne(){
        setKitchenOne('block');
        setKitchenTwo('none');
        setKitchenThree('none');
    }

    function handlerKitchenTwo(){
        setKitchenOne('none');
        setKitchenTwo('block');
        setKitchenThree('none');
    }

    function handlerKitchenThree(){
        setKitchenOne('none');
        setKitchenTwo('none');
        setKitchenThree('block');
    }

    return (
        <div>
            <div className="Kitchen-Links">
                <div className="Kitchen-Icon">                    
                    <img src={KitchenIconOne} onClick={handlerKitchenOne} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: kitchenOne }}></div>
                </div>
                <div className="Kitchen-Icon">
                    <img src={KitchenIconTwo} onClick={handlerKitchenTwo} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: kitchenTwo }}></div>
                </div>
                <div className="Kitchen-Icon">
                    <img src={KitchenIconThree} onClick={handlerKitchenThree} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: kitchenThree }}></div>
                </div>                  
            </div>
            <div className="boxes-Kitchen">
                <WhatIs display={kitchenOne}/>   
                <WhySub display={kitchenTwo}/> 
                <SneakPeak display={kitchenThree}/>                        
            </div>
        </div>
    );
}

export default KitchenIcons; 