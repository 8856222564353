import './LearnAboutIcons.css';
import React, { useState } from "react";
import LearnIconOne from "../../images/Callie_Icon.webp";
import LearnIconTwo from "../../images/Callie_Kyle_Icon.webp";
import LearnIconThree from "../../images/Smiths_Icon.webp";
import AboutCallie from './AboutCallie';
import AboutKyleCallie from './AboutKyleCallie';
import AboutFamily from './AboutFamily';

const LearnAboutIcons = () => {

    const [LearnOne, setLearnOne] = useState("block");
    const [LearnTwo, setLearnTwo] = useState("none");
    const [LearnThree, setLearnThree] = useState("none");

    function handlerLearnOne(){
        setLearnOne('block');
        setLearnTwo('none');
        setLearnThree('none');
    }

    function handlerLearnTwo(){
        setLearnOne('none');
        setLearnTwo('block');
        setLearnThree('none');
    }

    function handlerLearnThree(){
        setLearnOne('none');
        setLearnTwo('none');
        setLearnThree('block');
    }

    return (
        <div>
            <div className="Learn-Links">
                <div className="Learn-Icon">                    
                    <img src={LearnIconOne} onClick={handlerLearnOne} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: LearnOne }}></div>
                </div>
                <div className="Learn-Icon">
                    <img src={LearnIconTwo} onClick={handlerLearnTwo} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: LearnTwo }}></div>
                </div>
                <div className="Learn-Icon">
                    <img src={LearnIconThree} onClick={handlerLearnThree} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: LearnThree }}></div>
                </div>                  
            </div>
            <div className="boxes-Learn">
                <AboutCallie display={LearnOne}/>   
                <AboutKyleCallie display={LearnTwo}/> 
                <AboutFamily display={LearnThree}/>                        
            </div>
        </div>
    );
}

export default LearnAboutIcons; 