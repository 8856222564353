import './MobileItems.css';
import { Outlet, Link } from "react-router-dom";

const MobileItems = (props) => {

function changeText(item){
    item = item.replace("/","");
    return item
}

var checkMobileItem = "Callie's Newsletter";
var checkPPAItem = "PPA TOUR";
var checkMLP = "MLP";
var checkDestination = "Destination Camps";


    return(
        <li onClick={props.showNav} className="nav-item">
            <a>{props.title}</a>
            <div className="sub-nav" id={props.id} style={{ maxHeight: props.height }}>
                {props.items.map((item) => (
                    <li className="nav-link">{item.name === checkMobileItem ? ( <a href="https://view.flodesk.com/pages/619c7f6148c53bc0f78e5f46">Callie's Newsletter</a>) : item.name === checkPPAItem ? (<a href="https://www.ppatour.com/pro/callie-smith/">PPA TOUR</a>) : item.name === checkMLP ? (<a href="https://www.majorleaguepickleball.net/player/callie-jo-smith/">MLP</a>) : item.name === checkDestination ? (<a href="https://jaydevilliers.com/vacation" >Destination Camps</a>) : (<Link key={item.name} to={String(item.link)} onClick={() => props.closeNav()}>{item.name}</Link>)}</li>
                ))}
            </div>
        </li>

    )
}

export default MobileItems