import './Performance.css';
import PerformanceIcons from './PerformanceIcons';

const Performance = (props) => {
    return (
        <div>
            <div className="gradient-bar"></div>
            <div className='page-Headers'><h1 className='tour-performanceH1'>{props.newItem}</h1></div>
            <PerformanceIcons />
        </div>
    )

}

export default Performance;