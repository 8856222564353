import './Nike.css';
import Scheduling from './Scheduling';
import nikeOne from "../../images/Nike_Image_Two.webp";
import nikeTwo from "../../images/Nike_Image_One.webp";
import Emailmodal from "../Emailmodal_nike.js";
import React, { useState, useEffect } from "react";
import axios from "axios";

const Nike = (props) => {

    const [dataNike, setData] = useState(null);
    const [loadingNike, setLoading] = useState(true);
    const [errorNike, setError] = useState(null);


    useEffect(() => {
        var url = "/Members/get_nike.php";
        axios.post(url)
            .then(function (response) {
                var callie_ids = response.data;
                var callie = new Array();
                callie_ids.forEach(clinic => {
                    var dd = parseFloat(clinic.clinic_start_time);
                    if(dd > 11){
                        if(dd == 12){
                            dd.toString();
                            clinic.clinic_start_time = dd + ":00 pm";                            
                        } else {
                            dd = dd - 12;
                            dd.toString();
                            clinic.clinic_start_time = dd + ":00 pm";
                        }
                    } else {
                        dd.toString();
                        clinic.clinic_start_time = dd + ":00 am";                        
                    }
                    callie.push(
                        {
                            id: clinic.clinic_id,
                            name: clinic.clinic_name,
                            date: clinic.clinic_date,
                            time: clinic.clinic_start_time,
                            location: clinic.clinic_location,
                            link: clinic.clinic_link
                        }
                    )
                });
                setData(callie);
            }).catch((err) => {
                setError(err.message);
                setData(null);
            }).finally(() => {
                setLoading(false);
            });
    }, []);


    const [modalState_nike, changeModal_nike] = useState("none");

    function Listener_Modal_nike() {
        if (modalState_nike == "none") {
            changeModal_nike("block");
        } else {
            changeModal_nike("none");
        }
    }

    function closedModal_nike(event) {
        if (event == document.querySelector('.modal-Nike')) {
            changeModal_nike("none");
        }
    }

    return (
        <div style={{ display: props.display }}>
            <div className='top-header'><h1>NIKE CAMPS</h1></div>
            <div className='follow-bg'>
                <h1 className='middleHeader'>Nike Camps near YOU:</h1>
                {loadingNike && <div>A moment please...</div>}
                {dataNike === null || (Array.isArray(dataNike) && dataNike.length === 0) ? (
                        <h2>New Nike Camps Coming Soon!</h2>
                    ) : (
                        Array.isArray(dataNike) && dataNike.map((schedule) => (
                            <Scheduling
                                key={schedule.id}
                                date={schedule.date}
                                time={schedule.time}
                                name={schedule.name}
                                location={schedule.location}
                                link={schedule.link}
                            />
                        ))
                    )}
                
                <div className='paddle-quote'>
                    <div className='clinic-p'>
                        <div className='border-line'>
                            <p className='clinic-quote'>''Amazing content exceeding all expectations. Everything was clear and so easy to follow and understand. We are looking forward to improving our game with all that Callie and her team taught us.'' </p>
                            <h3>Joe Protano</h3>
                        </div>
                    </div>

                </div>
                <div className='nike-boxes'>
                    <div className='nikeBoxOne'>
                        <h1>Schedule a New Camp</h1>
                        <p>Schedule Callie's next Nike Camp NEAR YOU!</p>
                        <button onClick={Listener_Modal_nike} className='clinic_button'>Contact Callie</button>
                        <Emailmodal callevent={closedModal_nike} display={modalState_nike} showmodal={Listener_Modal_nike} />
                    </div>
                    <div className='nikeBoxTwo'>
                    {window.innerWidth > 1000 ? <img src={nikeOne} className='nikeOneImg' /> : ''}                         
                    </div>
                </div>
                <div className='nike-big-image'>
                    <img src={nikeTwo} className='nike-main' />
                </div>
                <br />

            </div>
            <br />
        </div>
    );
}

export default Nike;