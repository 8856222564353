import './Follow.css';
//import clinic from "./images/main_coaching.jpg";
import insta from "../../webp/Insta_Icon.webp";
import shopimg from "../../webp/shopHome.webp";
import facebook from "../../webp/FaceBook_Icon.webp";
import tiktok from "../../webp/TikTok_Icon.webp";
import linktree from "../../webp/LinkTree_Icon.webp";
import fam from "../../webp/main_family.webp";
import { Outlet, Link } from "react-router-dom";

const Shop = (props) => {


    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>Shop Callie Products</h1>
            </div>
            <div className='follow-bg'>
                <div className='boxes-f'>
                    <div className='box-f'>
                        <div className='pad-follow'>
                            <h3 style={{ textAlign: "left" }}>Callie Jo Sports</h3>
                            <p className='p-follow'>Callie is EXTREMELY meticulous when it comes to the clothes that she wears. Providing clothes for on and off the court, Callie Jo Sports gives you comfortable and stylish options! </p>
                            <div style={{ textAlign: "center" }}>
                                <a href="https://calliejosports.com/"><button className='follow-button'>Shop Now</button></a>
                            </div>
                        </div>
                    </div>
                    <div className='box-f'>
                        <img src={shopimg} className='follow-img' />
                    </div>
                </div>
                <div className='follow-bottom'>
                    <p className='social-p'>You can also Subscribe to Callie's Newsletter to get a behind the scenes look into Callie's Pro Tour Experience!<br />
                    <Link to="/Kitchen"><button className='follow-button'>Callies's Newsletter</button></Link>

                    </p>
                    
                    <div className='social-d'>
                        <div>
                            <a target="_blank" href="https://www.tiktok.com/t/ZTRNvpEEq/"><img src={tiktok} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://www.facebook.com/calliejo.smith.58"><img src={facebook} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://www.instagram.com/calliejosmith_pickleball/?hl=en"><img src={insta} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://linktr.ee/Calliejosmith"><img src={linktree} className='social-button' /></a>
                        </div>
                    </div>
                </div>

            </div>
            <br />
        </div>
    )
}

export default Shop