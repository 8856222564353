import './Emailmodal_Linktree.css';
import axios from "axios";
import React, { useState } from "react";
import sendTelegramMessage from './TelegramSend.js';
import sendToDatabase from './AddToDatabase.js';

const Emailmodal_Linktree = (props) => {

    window.onclick = function (event) {
        props.callevent(event.target);
    }

    function isContentValid_link(event) {
        event.preventDefault();
        var isvalid = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        var name_link = document.getElementById('name_link');
        var email_link = document.getElementById('email_link');
        var topic_link = document.getElementById('topic_link');
        var errors = 0;

        if (name_link.value == "") {
            name_link.value = "Please enter your name";
            errors++;
        } 

        if(!isvalid.test(email_link.value)){
            email_link.value = "Please enter a valid email";
            errors++;
        }

        if(topic_link.value == ""){
            topic_link.value = "Please enter your subject";
            errors++;
        }

        if(errors > 0){
            alert("please follow the prompts and fill out the proper information");
        } else {   
            var url =  "/Members/send_email.php?name=" + name_link.value + "&email=" + email_link.value + "&topic=" + topic_link.value;      
            sendTelegramMessage(name_link.value, email_link.value, topic_link.value);
            sendToDatabase(name_link.value, email_link.value, topic_link.value);
            props.showmodal();
            //axios.post(url).then(function(response){
               // alert('Email Sent!');
                //props.showmodal();
            // });
        }

    }


    return (
        <div className='modal-Link' style={{ display: props.display }}>
            <div className="modal-content">
                <span onClick={props.showmodal} className="close">&times;</span>
                <br />
                <form onSubmit={isContentValid_link}>
                    <h2>Contact Callie</h2>
                    <p> Name <input type="text" id="name_link" name="name_link" /></p>
                    <p> Email Address <input type="text" id="email_link" name="email_link" /></p>
                    <p> Subject/Topic<textarea id="topic_link" name="topic_link" rows="8" cols="25"></textarea></p>
                    <div style={{ textAlign: "center" }}><input type="submit" name="submit" value="Submit" /></div>
                </form>
                
            </div>
        </div>
    )


}

export default Emailmodal_Linktree;