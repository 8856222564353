

export default function sendTelegramMessage(name, email, topic){

  if(email == "79xvtx14@frontier.com" || email == "jncie@frontier.com"){
    alert('Email Sent!');
  } else {
    let newChat = '-1001670265923';
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.telegram.org/bot6242171036:AAH8VNUAgbLP8pr55EZmftC5r7WuWy6fhug/sendMessage");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function (){
    if (xhr.readyState === 4 && xhr.status === 200) {
        var responseData = JSON.parse(xhr.responseText);
        console.log(responseData.ok);
        if(responseData.ok == true){
          alert('Email Sent!');
        } else {
          console.log("did not send");
        }
        
    }};
    var submitQ = "Submission from" + name + '\n\n'
                   + "Email = " + email + '\n\n'
                   + topic;
    console.log("Telegram " + newChat);
    let data = JSON.stringify({
        "chat_id": newChat,
        "text": submitQ,
        "parse_mode": "HTML"

    });            
    console.log(data);
    xhr.send(data);
  }

    
}


