import './LearnAboutCallie.css';
import LearnAboutIcons from './LearnAboutIcons';

const LearnAboutCallie = () => {
    return (
        <div>
            <div className="gradient-bar"></div>
            <div className='page-Headers'><h1>LEARN ABOUT CALLIE</h1></div>
            <LearnAboutIcons />
        </div>
        
    )
}

export default LearnAboutCallie; 