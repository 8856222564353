import './AboutCallie.css';
import callieOneImg from '../../images/Callie_About_One.webp';
import callieTwoImg from '../../images/Callie_About_Two.webp';
import React, { useState } from "react";
import insta from "../../images/Insta_Icon.webp";
import facebook from "../../images/FaceBook_Icon.webp";
import tiktok from "../../images/TikTok_Icon.webp";
import linktree from "../../images/LinkTree_Icon.webp";

var callieMobile =
    <div className='callie-boxOne'>
        <div className='image-callie-box'>
            <img src={callieTwoImg} className='callie-images' />
        </div>
        <br />
        <div className='tennis-box'>
            <h3>Tennis</h3>
            <p>Callie is a 4 time high school Utah State
                Champion in women's tennis. Callie went
                on to play at Brigham Young University as
                a Freshman in college, but transferred to
                the University of Utah before her Sophomore year. While at Utah she played in
                the PAC-12 Conference with tennis
                powerhouses like UCLA, USC, and Stanford. During her Senior year she amassed
                a Single's record of 19-5 and a Double's
                record of 18-3. Her combined overall
                college tennis record was 143-64. Callie
                received several awards for her prowess
                as a student-athlete and finished at the
                University of Utah with a 4.0 GPA.</p>
        </div>
    </div>

var callieNotMobile =
    <div className='callie-boxOne'>
        <div className='tennis-box'>
            <h3>Tennis</h3>
            <p>Callie is a 4 time high school Utah State
                Champion in women's tennis. Callie went
                on to play at Brigham Young University as
                a Freshman in college, but transferred to
                the University of Utah before her Sophomore year. While at Utah she played in
                the PAC-12 Conference with tennis
                powerhouses like UCLA, USC, and Stanford. During her Senior year she amassed
                a Single's record of 19-5 and a Double's
                record of 18-3. Her combined overall
                college tennis record was 143-64. Callie
                received several awards for her prowess
                as a student-athlete and finished at the
                University of Utah with a 4.0 GPA.</p>
        </div>
        <br />
        <div className='image-callie-box'>
            <img src={callieTwoImg} className='callie-images' />
        </div>
    </div>

const AboutCallie = (props) => {
    return (
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>CALLIE</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='callie-boxes'>
                    {window.innerWidth > 1000 ? callieNotMobile : callieMobile}
                    <div className='callie-boxTwo'>
                        <div className='image-callie-box'>
                            <img src={callieOneImg} className='callie-images' />
                        </div>
                        <br />
                        <div className='pickleball-box'>
                            <h3>Pickleball</h3>
                            <p>Callie was introduced to pickleball by
                                Kyle's 85 year old grandpa, who invited
                                her to play in a local tournament. Callie
                                had NO INTENTION of playing again.
                                Luckily, that tournament introduced Callie
                                to several people who are still some of her
                                closest friends today. Callie decided to
                                play professionally in 2019 - and immediately vaulted into the top ranks of pickleball. Since 2019 she has amassed over 70
                                medals at professional events and has
                                finished each calendar year ranked as a
                                Top 5 Women's Player in the world. In
                                2021 she signed with the Professional
                                Pickleball Association (PPA), has medaled
                                in every PPA tournament since, and took
                                Gold with Catherine Parenteau in the
                                2021 U.S. Open Women's Doubles Event.
                                Callie's eventual goal is to become the #1
                                women's player in the world. </p>
                        </div>
                    </div>
                </div>
                <br />
                <div className='LearnMoreAbout'>
                    <h3>Want to Learn More?</h3>
                </div>
                <div className='follow-bottom'>
                    <p className='social-p'>Follow Callie on social media for quick coaching tips that will help you improve your pickleball game!</p>
                    <div className='social-d'>
                        <div>
                            <a target="_blank" href="https://www.tiktok.com/t/ZTRNvpEEq/"><img src={tiktok} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://www.facebook.com/calliejo.smith.58"><img src={facebook} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://www.instagram.com/calliejosmith_pickleball/?hl=en"><img src={insta} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://linktr.ee/Calliejosmith"><img src={linktree} className='social-button' /></a>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default AboutCallie;