import './PerformanceIcons.css';
import PpaIcon from "../../webp/PpaTourIcon.webp";
import MajorIcon from "../../webp/MajorsIcon.webp";
import OtherIcon from "../../webp/OtherIcons.webp";
import PpaTour from './PpaTour';
import MajorTour from './MajorTour';
import OtherTour from './OtherTour';
import React, { useState, useEffect } from "react";
import axios from "axios";



const PerformanceIcons = () => {

    const [dataMedals, setData] = useState(null);
    const [loadingMedals, setLoading] = useState(true);
    const [errorMedals, setError] = useState(null);


    useEffect(() => {
        var url = "/Members/get_medals.php";
        axios.post(url)
            .then(function (response) {
                var medals_check = response.data;
                const medalsO =
                        {
                            PPAt: medals_check[0].medal_count,
                            PPAm: medals_check[1].medal_count,
                            Majort: medals_check[2].medal_count,
                            Majorm: medals_check[3].medal_count,
                            Othert: medals_check[4].medal_count,
                            Otherm: medals_check[5].medal_count
                        };
                        console.log(medalsO);
                setData(medalsO);
            }).catch((err) => {
                setError(err.message);
                setData(null);
            }).finally(() => {
                setLoading(false);
            });
    }, []);



    const [PerfOne, setPerfOne] = useState("block");
    const [PerfTwo, setPerfTwo] = useState("none");
    const [PerfThree, setPerfThree] = useState("none");

    function handlerPerfOne() {
        setPerfOne('block');
        setPerfTwo('none');
        setPerfThree('none');
    }

    function handlerPerfTwo() {
        setPerfOne('none');
        setPerfTwo('block');
        setPerfThree('none');
    }

    function handlerPerfThree() {
        setPerfOne('none');
        setPerfTwo('none');
        setPerfThree('block');
    }

    return (
        <div>
            <div className="Perf-Links">
                <div className="Perf-Icon">
                    <img src={PpaIcon} onClick={handlerPerfOne} style={{ cursor: "pointer" }} />
                    <div className="triangle" style={{ display: PerfOne }}></div>
                </div>
                <div className="Perf-Icon">
                    <img src={MajorIcon} onClick={handlerPerfTwo} style={{ cursor: "pointer" }} />
                    <div className="triangle" style={{ display: PerfTwo }}></div>
                </div>
                <div className="Perf-Icon">
                    <img src={OtherIcon} onClick={handlerPerfThree} style={{ cursor: "pointer" }} />
                    <div className="triangle" style={{ display: PerfThree }}></div>
                </div>
            </div>

            {loadingMedals && <div>A moment please...</div>}
            {errorMedals && (
                <div>{`There is a problem fetching the post data - ${errorMedals}`}</div>
            )}
            {dataMedals && <div className="boxes-Perf">
                <PpaTour display={PerfOne} ppaTitles={dataMedals.PPAt} ppaMedals={dataMedals.PPAm}/>
                <MajorTour display={PerfTwo} majorTitles={dataMedals.Majort} majorMedals={dataMedals.Majorm}/>
                <OtherTour display={PerfThree} otherTitles={dataMedals.Othert} otherMedals={dataMedals.Otherm}/>
            </div>
            }

        </div>
    )
}

export default PerformanceIcons;