import './MajorTour.css';
import MajortourOne from '../../webp/majorNewOne.webp';
import MajortourTwo from '../../webp/majorNewTwo.webp';




const MajorTour = (props) => {

    var mobileMajor =
    <div className='othermajor'>
        <div className='othermajorImage'>
            <img src={MajortourTwo} className="mainTourImage" />
        </div>
        <div className='othermajorText'>
            <div className='othermajorTitles'>
                <div className='othermajorP'><h1>Major Medals:</h1></div>
                <div className='othermajorH1'><h1 className='majorMedals'>{props.majorMedals}</h1></div>
            </div>
        </div>
    </div>;

var NotmobileMajor =
    <div className='othermajor'>
        <div className='othermajorText'>
            <div className='othermajorTitles'>
                <div className='othermajorP'><h1>Major Medals:</h1></div>
                <div className='othermajorH1'><h1 className='majorMedals'>{props.majorMedals}</h1></div>
            </div>
        </div>
        <div className='othermajorImage'>
            <img src={MajortourTwo} className="mainTourImage" />
        </div>
    </div>;

    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>MAJOR TOURNAMENTS</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='majorBox'>
                    <p className='majorPara'>The Minto US Open Pickleball Championships & Margaritaville National Championships are hosted annually. Since their launch they have been two of the most attended tournaments by players all across the world!</p>
                </div>                  
                <div className='majorRibbons'>
                    <div className='majorImage'>
                        <img src={MajortourOne} className="mainTourImage" />
                    </div>
                    <div className='majorText'>
                        <div className='titlesmajorText'>
                            <div className='majorP'><h1>Major Titles:</h1></div>
                            <div className='majorH1'><h1 className='majorMedals'>{props.majorTitles}</h1></div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 600 ?  NotmobileMajor : mobileMajor}
            </div> 
            <br />      
        </div>
    )
}

export default MajorTour;