import './AboutFamily.css';
import familyOne from '../../images/learnAboutOne.webp';
import familyTwo from '../../images/learnAboutTwo.webp';
import insta from "../../images/Insta_Icon.webp";
import facebook from "../../images/FaceBook_Icon.webp";
import tiktok from "../../images/TikTok_Icon.webp";
import linktree from "../../images/LinkTree_Icon.webp";

var familyNotMobile = 
<div className='family-boxes'> 
<div className='family-box'>
<h3>The Smith Family</h3>
<p>Callie & Kyle have been blessed with 2
    beautiful children - their daughter, Camber,
    and their son, Stockton. Both have been
    heavily involved in pickleball since they were
    born. Callie played her first pickleball tournament when Camber was less than a year old;
    years later, Callie used a “pregnancy sling”
    around her stomach to play while 7 months
    pregnant with Stockton.</p>
<p>
    Camber and Stockton still travel with their
    mom and dad to tournaments all around the
    country - but typically stick to tournaments
    that can be driven to from the Smith home in
    Utah. They LOVE IT when Callie wins and are
    always excited for when she comes home
    with “their” medals.
</p>
<p>
    Of all the accomplishments that Callie &
    Kyle have attained in their lives, they consider
    their greatest accomplishment and joy to
    be their family
</p>
</div>                    
<div className='family-boxTwo'>
<div className='image-family-box'>
<img src={familyOne} className='family-images' />
</div>
<br />
<div className='image-family-box'>
<img src={familyTwo} className='family-images' />
</div>
</div>
</div>

var  familyMobile = 
<div className='family-boxes'> 
<div className='family-boxTwo'>
<div className='image-family-box'>
<img src={familyOne} className='family-images' />
</div>
</div>
<div className='family-box'>
<h3>The Smith Family</h3>
<p>Callie & Kyle have been blessed with 2
    beautiful children - their daughter, Camber,
    and their son, Stockton. Both have been
    heavily involved in pickleball since they were
    born. Callie played her first pickleball tournament when Camber was less than a year old;
    years later, Callie used a “pregnancy sling”
    around her stomach to play while 7 months
    pregnant with Stockton.</p>
<p>
    Camber and Stockton still travel with their
    mom and dad to tournaments all around the
    country - but typically stick to tournaments
    that can be driven to from the Smith home in
    Utah. They LOVE IT when Callie wins and are
    always excited for when she comes home
    with “their” medals.
</p>
<p>
    Of all the accomplishments that Callie &
    Kyle have attained in their lives, they consider
    their greatest accomplishment and joy to
    be their family
</p>
</div>                    

</div>
const AboutFamily = (props) => {
    return (
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>SMITH FAMILY</h1>
            </div>
            <div className='kitchen-bg'>                                   
                {window.innerWidth > 1000 ? familyNotMobile : familyMobile}                 
                <br />
                <div className='LearnMoreAbout'>
                    <h3>Want to Learn More?</h3>
                </div>
                <div className='follow-bottom'>
                    <p className='social-p'>Follow Callie on social media for quick coaching tips that will help you improve your pickleball game!</p>
                    <div className='social-d'>
                        <div>
                            <a target="_blank" href="https://www.tiktok.com/t/ZTRNvpEEq/"><img src={tiktok} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://www.facebook.com/calliejo.smith.58"><img src={facebook} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://www.instagram.com/calliejosmith_pickleball/?hl=en"><img src={insta} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://linktr.ee/Calliejosmith"><img src={linktree} className='social-button' /></a>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default AboutFamily;