import React from "react";
import './Coaching.css';
import ClinicsIcon from "./ClinicsIcon";

const Coaching = () => {
    return (
     <div>
         <div className="gradient-bar"></div>
         <div className='page-Headers'><h1>COACHING</h1></div>
         <ClinicsIcon />
     </div>   
    );
}

export default Coaching;