import './Follow.css';
//import clinic from "./images/main_coaching.jpg";
import insta from "../../webp/Insta_Icon.webp";
import facebook from "../../webp/FaceBook_Icon.webp";
import tiktok from "../../webp/TikTok_Icon.webp";
import linktree from "../../webp/LinkTree_Icon.webp";
import fam from "../../webp/main_family.webp";
import { Outlet, Link } from "react-router-dom";

const Follow = (props) => {


    return (
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>FOLLOW CALLIE</h1>
            </div>
            <div className='follow-bg'>
                <div className='boxes-f'>
                    <div className='box-f'>
                        <div className='pad-follow'>
                            <h3 style={{ textAlign: "left" }}>Callie's Social</h3>
                            <p className='p-follow'>Need some help leveling up your pickleball game to the next level? Callie regularly posts quick tips and tricks to give you the insights you need to improve!.</p>
                            <div style={{ textAlign: "center" }}>
                                <Link to="/SocialMedia"><button className='follow-button'>Follow Callie</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='box-f'>
                        <img src={fam} className='follow-img' />
                    </div>
                </div>
                <div className='follow-bottom'>
                    <p className='social-p'>You can also Subscribe to Callie's Newsletter to get a behind the scenes look into Callie's Pro Tour Experience!<br />
                    <Link to="/Kitchen"><button className='follow-button'>Callies's Newsletter</button></Link>

                    </p>
                    
                    <div className='social-d'>
                        <div>
                            <a target="_blank" href="https://www.tiktok.com/t/ZTRNvpEEq/"><img src={tiktok} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://www.facebook.com/calliejo.smith.58"><img src={facebook} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://www.instagram.com/calliejosmith_pickleball/?hl=en"><img src={insta} className='social-button' /></a>
                        </div>
                        <div>
                        <a target="_blank" href="https://linktr.ee/Calliejosmith"><img src={linktree} className='social-button' /></a>
                        </div>
                    </div>
                </div>

            </div>
            <br />
        </div>
    )

}

export default Follow