import './boxes.css';
import clinic from "../../webp/main_coaching.webp";
import { Outlet, Link } from "react-router-dom";
import Emailmodal from "../Emailmodal_nav.js";
import React, { useState } from "react";


const Clinics = (props) => {

    const [modalState_home, changeModal_home] = useState("none");

    function Listener_Modal_Nav() {

        if (modalState_home == "none") {
            changeModal_home("block");
        } else {
            changeModal_home("none");
        }
        
    }

    function closed_Modal_Nav(event) {
        if (event == document.querySelector('.modal-Nav')) {
            changeModal_home("none");
        }
    }


return (
                <div style={{ display: props.display }}>
                    <div className="clinics_b">
                        
                        <h1 className='h1_clincis'>COACHING</h1>
                        <div className='clinics_boxes'>
                            <div className='clinics_box'>
                            {window.innerWidth > 1000 ? <img src={clinic} className='clinics-img'/> : ''}                                
                            </div>
                            <div className='clinics_box'> 
                                    <div className='pad-box'>
                                    <p className='p-clinics border-line'>''Callie takes a personal interest in your goals. My goal has become Callie's goal. That is why she can take anyone to any level. Anyone trying to get to the next level will find it's possible with Callie.''</p>
                                    <p className='bold-clinics'><b>Scott Johnston</b> </p> 
                                    </div>                                                     
                            </div>
                        </div>
                        <h3 className='h3_clinics'>Schedule a time with Callie through one of the following options:</h3>
                    </div>
                    <div className='clincis_g'>
                        <h3 className='clinics_g_h3'>Online Instruction:</h3>
                        <div className='instruct'>
                            <div className='clinics_online_p'>
                                <p>Sign up for a Clinic with Callie OR Nike Camp near you:</p>
                                <Link to="/Coaching"><button className='clinic_button'>Sign Up</button></Link>
                            </div>
                            <div>
                                <h1>OR</h1>
                            </div>
                            <div  className='clinics_online_p'>
                                <p>Contact Callie to set up a Clinic near you:</p>
                                <a onClick={Listener_Modal_Nav}><button className='clinic_button'>Contact Us</button></a>
                            </div>
                        </div>
                        
                        
                        <h3 className='clinics_g_h3'>Destination Camps with Callie & Jay Devilliers:</h3>
                        <div className='clinics_callie_p'>
                        <p>Enjoy an exciting vacation while getting instruction from Callie & Jay Devilliers!</p>
                        </div>
                        <a href="https://jaydevilliers.com/vacation"><button className='clinic_button'>Learn More!</button></a>
                        <br /><br />
                    </div>
                    <br />
                    <Emailmodal callevent={closed_Modal_Nav} display={modalState_home} showmodal={Listener_Modal_Nav} />
                </div>
)

}

export default Clinics