import './Nav.css';
import logo from '../images/callie-logo.webp';
import React, { useState } from "react";
import Hamburger from 'hamburger-react';
import { Outlet, Link } from "react-router-dom";
import Mobile from '../components/mobile';
import Emailmodal from "./Emailmodal_nav.js";



function Nav(props) {

    const [coachNav, setCoachNav] = useState("none");
    const [followNav, setFollowNav] = useState("none");
    const [proNav, setProNav] = useState("none");
    const [sponNav, setSponNav] = useState("none");
    const [modalState_home, changeModal_home] = useState("none");

    function setOffs(callfunction){
        setSponNav("none");
    }

    function setS(callfunction){
        setSponNav("block");
    }

    function setC(callfunction) {
        setCoachNav("block");
    }

    function setOffC(callfunction) {
        setCoachNav("none");
    }

    function setF(callfunction) {
        setFollowNav("block");
    }

    function setOffF(callfunction) {
        setFollowNav("none");
    }

    function setP(callfunction) {
        setProNav("block");
    }

    function setOffP(callfunction) {
        setProNav("none");
    }


    const [isOpen, setOpen] = useState(false);

    function showMobileNav() {
        
        if (isOpen) {
            setOpen(false);
            console.log("closed");
            document.getElementById('dropDownMobile').style.maxHeight = "0px";
            document.getElementById('CoachingMobile').style.maxHeight = "0px";
            document.getElementById('FollowMobile').style.maxHeight = "0px";
            document.getElementById('ProTour').style.maxHeight = "0px";

            //document.getElementById('dropDownMobile').style.display = "block";
        } else {
            setOpen(true);
            
            document.getElementById('dropDownMobile').style.maxHeight = "500px";

            //document.getElementById('dropDownMobile').style.display = "none";
        }
    }

    function close_logo(){
        setOpen(false);
        console.log("closed");
        document.getElementById('dropDownMobile').style.maxHeight = "0px";
        document.getElementById('CoachingMobile').style.maxHeight = "0px";
        document.getElementById('FollowMobile').style.maxHeight = "0px";
        document.getElementById('ProTour').style.maxHeight = "0px";
    }

    function downLine() {
        showMobileNav();
    }



    function Listener_Modal_Nav() {

        if (modalState_home == "none") {
            changeModal_home("block");
        } else {
            changeModal_home("none");
        }
        
    }

    function closed_Modal_Nav(event) {
        if (event == document.querySelector('.modal-Nav')) {
            changeModal_home("none");
        }
    }



    //
    //onMouseOut={setNavOff(setFollowNav)} onMouseOver={setNavOn(setFollowNav)}
    //onMouseOut={setNavOff(setCoachNav)} onMouseOver={setNavOn(setCoachNav)}

    return (
        <div className='nav-grid'>

            <div className="logoSec">
                <Link to="/"><img onClick={close_logo} src={logo} className="logo-size" /></Link>
            </div>

            <div className="navSec">

                <nav className="navbar navbar-expand-lg">

                    <div className="container-fluid">

                        <div className="collapse navbar-collapse" id="navbarNavDropdown">

                            <ul className="navbar-nav moveDown">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="index"><Link to="/">Home</Link></a>
                                </li>

                                <li className="nav-item" id="dropNavOne" onMouseOut={setOffC} onMouseOver={setC}>
                                    <a className="nav-link firstD" ><span>Coaching</span></a>
                                    <ul id='CoachingNav' style={{ display: coachNav }} className="coachDrop">
                                        <li><Link to="/Coaching">Clinics with Callie</Link></li>
                                        <li><a href="https://jaydevilliers.com/vacation">Destination Camps</a></li>
                                        {/*<li><Link to="/Online">Online Instruction</Link></li>*/}
                                        
                                    </ul>
                                </li>

                                <li className="nav-item" id="dropNavTwo" onMouseOut={setOffF} onMouseOver={setF}>
                                    <span><a className="nav-link firstD">Follow Callie</a></span>
                                    <ul style={{ display: followNav }} className="FollowDrop">
                                    <li><a href="https://view.flodesk.com/pages/619c7f6148c53bc0f78e5f46">Callie's Newsletter</a></li>
                                        <li><Link to="/LearnAbout">Learn About Callie</Link></li>
                                        <li><Link to="/SocialMedia">Callie's Social Media</Link></li>
                                    </ul>
                                </li>

                                <li className="nav-item" id="dropNavThree" onMouseOut={setOffP} onMouseOver={setP}>
                                    <span><a className="nav-link firstD">Pro Tour</a></span>
                                    <div style={{ display: proNav }} className="TourDrop">
                                        <li><Link to="/TourSchedule">Tour Schedule</Link></li>
                                        <li><Link to="/Career">Tour Performance</Link></li>
                                        <li><a href="https://www.ppatour.com/pro/callie-smith/">PPA TOUR</a></li>
                                        <li><a href="https://www.majorleaguepickleball.net/player/callie-jo-smith/">MLP</a></li>
                                    </div>
                                </li>

                                <li className="nav-item" id="dropNavFour" onMouseOut={setOffs} onMouseOver={setS}>
                                    <span><a className="nav-link firstD">Sponsors</a></span>
                                    <div style={{ display: sponNav }} className="TourDrop">
                                        <li><Link to="/Sponsors">Callie's Sponsors</Link></li>
                                        <li><Link to="/Partnerships">Callie's Partnerships</Link></li>
                                    </div>
                                </li>

                                <li className="nav-item">
                                    <a onClick={Listener_Modal_Nav} style={{cursor : "pointer"}}className="nav-link">Contact Us</a>
                                </li>
                                <Emailmodal callevent={closed_Modal_Nav} display={modalState_home} showmodal={Listener_Modal_Nav} />

                                <li className="nav-item">
                                    <a href="https://calliejosports.com/" target="_blank"><button className="login-B">View Store</button></a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>

            </div>
            <div className='nav-mobile'>
                <div className='hamburger'>
                    <Hamburger color="#3c80b0" toggled={isOpen} toggle={showMobileNav} />
                </div>

            </div>
            <Mobile nextMobile={downLine} />
        </div>

    );



}

export default Nav