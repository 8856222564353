import './SocialMedia.css';
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import insta from "../../images/Insta_Icon.webp";
import facebook from "../../images/FaceBook_Icon.webp";
import tiktok from "../../images/TikTok_Icon.webp";
import linktree from "../../images/LinkTree_Icon.webp";
import bodyBag from "../../videos/BodyBagOne.mp4";
import overhead from "../../videos/OverheadTech.mp4";
import whichPaddle from "../../videos/WhichPaddle.mp4";
import stock_camber from "../../videos/Stock_Camber_P.mp4";
import pbCourt from "../../videos/Courts_Newbies.mp4";
import inTheBag from "../../videos/WhatsInTheBag.mp4";
import laundry from "../../videos/FoldingLaundry.mp4";
import driving from "../../videos/OnDrive.mp4";
import pillow from "../../videos/Callie_Kyle_P.mp4";
import laundaryI from "../../images/LaundaryImage.webp";
import pillowI from "../../images/pillowTalkKC.webp";
import whatsInBagI from "../../images/whatsInTheBagImage.webp";
import drivingI from "../../images/drivingImage.webp";
import newbieI from "../../images/newbieImage.webp";
import camber_stockI from "../../images/camber_stock_image.webp";
import whichPaddleI from "../../images/WhichPaddleImage.webp";
import OverheadI from "../../images/OverheadTechImage.webp";
import bodyBagI from "../../images/bodyBagImage.webp";


import { useState } from "react";

const SocialMedia = (props) => {

    const [autoPlay, setAutoPlay] = useState(true);

    const onClick= (e, code) =>{
		toggleAutoPlay(e)
	}

	const toggleAutoPlay = (e) => {
		setAutoPlay((autoPlay) => !autoPlay);		
	};

    return (

        <div style={{ display: props.display }}>
            <div className="gradient-bar"></div>
            <br />
            <div className='page-Headers socialTop'><h1>CALLIE'S SOCIAL MEDIA</h1></div>
            <div className='social-header'>
                <h2>Get quick coaching tips & tricks from Callie that will help you improve your game!</h2>
            </div>
            <div className='kitchen-bg'>
                <div className='socialAbout'>
                    <h3>Check out some examples here!</h3>
                </div>
                <br />
                <MultiCarousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay={autoPlay}
                    autoPlaySpeed={3000}
                    centerMode
                    className="carousel-container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024,
                            },
                            items: 3,
                            partialVisibilityGutter: 40,
                        },
                        mobile: {
                            breakpoint: {
                                max: 768,
                                min: 0,
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464,
                            },
                            items: 2,
                            partialVisibilityGutter: 30,
                        },
                    }}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    <div className='car-box'>
                        <div className='social-box'>
                            
                            <video width="100%" poster={bodyBagI} onClick={(e) => onClick(e)} controls>
                                <source onClick={(e) => onClick(e)} src={bodyBag} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='car-box'>
                        <div className='social-box'>
                            
                            <video width="100%" poster={OverheadI} onClick={(e) => onClick(e)} controls>
                                <source onClick={(e) => onClick(e)} src={overhead} type="video/mp4" />
                            </video>
                        </div>

                    </div>

                    <div className='car-box'>
                        <div className='social-box'>
                            
                            <video width="100%" poster={whichPaddleI} onClick={(e) => onClick(e)} controls>
                                <source onClick={(e) => onClick(e)} src={whichPaddle} type="video/mp4" />
                            </video>
                        </div>

                    </div>

                    <div className='car-box'>
                        <div className='social-box'>
                            
                            <video width="100%" poster={camber_stockI} onClick={(e) => onClick(e)} controls>
                                <source onClick={(e) => onClick(e)} src={stock_camber} type="video/mp4" />
                            </video>
                        </div>

                    </div>

                    <div className='car-box'>
                        <div className='social-box'>
                            
                            <video width="100%" poster={newbieI} controls>
                                <source src={pbCourt} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='car-box'>
                        <div className='social-box'>
                            
                            <video width="100%" poster={whatsInBagI} controls>
                                <source src={inTheBag} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='car-box'>
                        <div className='social-box'>
                            
                            <video width="100%" poster={laundaryI} controls>
                                <source src={laundry} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='car-box'>
                        <div className='social-box'>
                        
                            <video width="100%" poster={drivingI} controls>                                
                                <source src={driving} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='car-box'>
                        <div className='social-box'>
                            
                            <video width="100%" poster={pillowI} controls>
                                <source src={pillow} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </MultiCarousel>
                <br />
                <div className='socialAbout'>
                    <h3>Want to Learn More?</h3>
                </div>
                <div className='follow-bottom'>
                    <p className='social-p'>Follow Callie on social media for quick coaching tips that will help you improve your pickleball game!</p>
                    <div className='social-d'>
                        <div>
                            <a target="_blank" href="https://www.tiktok.com/t/ZTRNvpEEq/"><img src={tiktok} className='social-button' /></a>
                        </div>
                        <div>
                            <a target="_blank" href="https://www.facebook.com/calliejo.smith.58"><img src={facebook} className='social-button' /></a>
                        </div>
                        <div>
                            <a target="_blank" href="https://www.instagram.com/calliejosmith_pickleball/?hl=en"><img src={insta} className='social-button' /></a>
                        </div>
                        <div>
                            <a target="_blank" href="https://linktr.ee/Calliejosmith"><img src={linktree} className='social-button' /></a>
                        </div>
                    </div>
                </div>
            </div>

            <br />
        </div>
    )

}

export default SocialMedia;