import './PpaTour.css';
import ppatourOne from '../../webp/ppaNewOne.webp';
import ppatourTwo from '../../webp/ppaNewTwo.webp';




const PpaTour = (props) => {
    
    var mobilePPA =
    <div className='otherRibbons'>
        <div className='otherImage'>
            <img src={ppatourTwo} className="mainTourImage" />
        </div>
        <div className='otherText'>
            <div className='otherTitles'>
                <div className='otherP'><h1>PPA Medals:</h1></div>
                <div className='otherH1'><h1 className='totalMedals'>{props.ppaMedals}</h1></div>
            </div>
        </div>
    </div>;

var NotmobilePPA =
    <div className='otherRibbons'>
        <div className='otherText'>
            <div className='otherTitles'>
                <div className='otherP'><h1>PPA Medals:</h1></div>
                <div className='otherH1'><h1 className='totalMedals'>{props.ppaMedals}</h1></div>
            </div>
        </div>
        <div className='otherImage'>
            <img src={ppatourTwo} className="mainTourImage" />
        </div>
    </div>;

    return (
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>PPA TOUR</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='ptourBox'>
                    <p className='ptourPara'>Since becoming an offcially signed PPA Tour Professional Athlete in 2020, Callie has amassed over 30 medals at PPA events and has consistently been ranked in the Top 5 in Women's Doubles & Singles.</p>
                </div>
                <div className='titleRibbons'>
                    <div className='ribbonImage'>
                        <img src={ppatourOne} className="mainTourImage" />
                    </div>
                    <div className='ribbonText'>
                        <div className='titlesText'>
                            <div className='titleP'><h1>PPA Titles:</h1></div>
                            <div className='titleH1'><h1 className='totalMedals'>{props.ppaTitles}</h1></div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 600 ?  NotmobilePPA : mobilePPA}
            </div>
            <br />
        </div>
    )
}

export default PpaTour;