import './CareerStats.css';
import { motion } from "framer-motion";

const CareerStats = ({uniquekey, data, side}) => {

     var keys = uniquekey;
    if(keys == 0){
        keys = .15;
    }
    var duration = 200 * keys;
    return(
        <motion.div
        initial={{ opacity: 0, x: -60 }}
        whileInView={{
            opacity: 1,
            x: 0,
        }}
        viewport={{ once: true }}
        transition={{
            type: "tween",
            duration: .8,
        }} 
        className='eventCard'          
        >
            <h2>{data.event}</h2>
            <p>Mixed Doubles: {data.mixed} </p>
            <p>Women's Doubles: {data.doubles} </p>
            {data.singles == 'DNP' ? '' : <p>Singles: {data.singles} </p>}

        </motion.div>
    )
}

export default CareerStats;