import React, { useState } from "react";
import './ClinicsIcon.css';
import clinicIconOne from "../../images/clinics.webp";
import clinicIconTwo from "../../images/nike.webp";
import Nike from './Nike';
import Paddles from './Paddles';

const ClinicsIcon = () => {

    const [clinicsOne, setClinicOne] = useState("block");
    const [clinicsTwo, setClinicTwo] = useState("none");

    function handlerClinicsOne(){
        setClinicOne("block");
        setClinicTwo("none");
    }

    function handlerClincisTwo(){
        setClinicOne("none");
        setClinicTwo("block");
    }

    return (
        <div>
            <div className="Clinics-Links">
                <div className="Clinics-Icon">                    
                    <img src={clinicIconOne} onClick={handlerClinicsOne} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: clinicsOne }}></div>
                </div>
                <div className="Clinics-Icon">
                    <img src={clinicIconTwo} onClick={handlerClincisTwo} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: clinicsTwo }}></div>
                </div>                
            </div>
            <div className="boxes-I">
                <Paddles display={clinicsOne}/>   
                <Nike display={clinicsTwo}/>                         
            </div>
        </div>


    );
}

export default ClinicsIcon;