import './Scheduling.css';
import location from '../../images/location_icon.webp';


const Scheduling = (props) => {

    let date = new Date(props.date.replace(/-/g, '\/'));
    //console.log(props.date);
    //console.log(date);
    let month = date.toLocaleString('en-US', { month: 'short' });
    let day = date.getDate();
    let year = date.getFullYear();
    const links = /http.*/;
    const regex = new RegExp("http.*");


    return (
        <div className='schedule-margin'>
            <div className="schedule-item" style={{ color: "black" }}>
                <div className="s-date"><p style={{ color: "black" }}>{month}</p><p style={{ color: "black" }} className='date-bottom'>{day}</p><p style={{ color: "black" }} className='date-bottom'>{year}</p></div>
                <div className="s-time">{props.name}<p style={{ color: "black" }} className='p-time'>{props.time}</p></div>
                <div className="s-location"><img src={location} className='location-icon' />{props.location}</div>
                <div className="s-button">
                {regex.test(props.link) ? <a href={props.link} target="_blank"><button className="s-link">Sign Up Now</button></a> : <a onClick={() => alert('Sign ups coming soon!')}><button className="s-link">Schedule Now</button></a>}                    
                </div>
            </div>
        </div>

    );
}

export default Scheduling;