import './Emailmodal_nav.css';
import axios from "axios";
import React, { useState } from "react";
import sendTelegramMessage from './TelegramSend.js';
import sendToDatabase from './AddToDatabase.js';

const Emailmodal_Nav = (props) => {

    window.onclick = function (event) {
        props.callevent(event.target);
    }

    function isContentValid_Nav(event) {
        event.preventDefault();
        var isvalid = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        var name_home = document.getElementById('name_home');
        var email_home = document.getElementById('email_home');
        var topic_home = document.getElementById('topic_home');
        var errors = 0;

        if (name_home.value == "") {
            name_home.value = "Please enter your name";
            errors++;
        } 

        if(!isvalid.test(email_home.value)){
            email_home.value = "Please enter a valid email";
            errors++;
        }

        if(topic_home.value == ""){
            topic_home.value = "Please enter your subject";
            errors++;
        }

        if(errors > 0){
            alert("please follow the prompts and fill out the proper information");
        } else {   
            var url =  "/Members/send_email.php?name=" + name_home.value + "&email=" + email_home.value + "&topic=" + topic_home.value;      
            sendTelegramMessage(name_home.value, email_home.value, topic_home.value);
            sendToDatabase(name_home.value, email_home.value, topic_home.value);
            props.showmodal();
            //axios.post(url).then(function(response){
               // alert('Email Sent!');
                //props.showmodal();
            // });
        }

    }


    return (
        <div className='modal-Nav' style={{ display: props.display }}>
            <div className="modal-content">
                <span onClick={props.showmodal} className="close">&times;</span>
                <br />
                <form onSubmit={isContentValid_Nav}>
                    <h2>Contact Callie</h2>
                    <p> Name <input type="text" id="name_home" name="name_home" /></p>
                    <p> Email Address <input type="text" id="email_home" name="email_home" /></p>
                    <p> Subject/Topic<textarea id="topic_home" name="topic_home" rows="8" cols="25"></textarea></p>
                    <div style={{ textAlign: "center" }}><input type="submit" name="submit" value="Submit" /></div>
                </form>
                
            </div>
        </div>
    )


}

export default Emailmodal_Nav;