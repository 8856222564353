
import './WhatIs.css';
import whyImage from '../../images/WhatIsImage.webp';

const WhatIs = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>WHAT IS CALLIE'S KITCHEN</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='why-boxes'>
                    <div className='why-boxOne'>
                        <h3>Callie's Kitchen is the official newsletter of top ranked pickleball player Callie Smith.</h3>
                        <p>Get a look at the everyday life of Callie and her family, interact with Callie directly, and get additional discounts for Callie's products and events!</p>
                        <a href="https://view.flodesk.com/pages/619c7f6148c53bc0f78e5f46"><button className='clinic_button'>Subscribe Today!</button></a>
                    </div>
                    <div className='why-boxTwo'>
                        <img src={whyImage} className='whyImageOne' />
                    </div>
                </div>           
            </div> 
            <br />      
        </div>
    )
}

export default WhatIs;