import './Onix.css';

const Hyundai = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>HYUNDAI</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='onix-box'>
                    <h2>Hyundai Murdock is the only car dealership that Callie purchases cars from. She and Kyle have 3 cars - all 3 HYUNDAI! Go check out some of their awesome deals today!</h2>
                    <div className='onix-button'>
                    <a href="https://www.murdockhyundailindon.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb&utm_term=gmb" target="_blank"><button className='clinic_button'>Shop Hyundai</button></a>
                    </div>                    
                </div>                         
            </div> 
            <br />      
        </div>
    )
}

export default Hyundai;