import './OnlineInstruction.css';
import OnlineIcon from './OnlineIcon';


const OnlineInstruction = () => {
    return (
     <div>
           <div className="gradient-bar"></div>
         <div className='page-Online'><h1>ONLINE INSTRUCTION</h1></div>
         <OnlineIcon />
     </div>   
    );
}

export default OnlineInstruction;