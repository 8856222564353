import './Optiva.css';

const Optiva = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>OPTIVIDA HEALTH</h1>
            </div>
            <div className='kitchen-bg'>
            <div className='opt-box'>
                    <h2>Optivida Health provides the ONLY supplemental products that Callie will use - because they are THE HEALTHIEST option anywhere!! Find out what products Callie & her family use EVERY SINGLE DAY!</h2>
                    <div className='opt-button'>
                    <a href="https://www.optividahealth.com/pages/callie?_pos=1&_sid=11d22af07&_ss=r" target="_blank"><button className='clinic_button'>Callie's OPTIVIDA Testimony</button></a>
                    <a href="https://linktr.ee/Calliejosmith" target="_blank"><button className='clinic_button'>Use Callie's Discount</button></a>
                    </div>
            </div>      
            </div> 
            <br />      
        </div>
    )
}

export default Optiva;