import './KitchenTable.css';

const KitchenTable = () => {
    return (
    <div>
        <table className='kitchen-table'>
            <thead>
                <tr>
                    <th className='thBen'>Benefits</th>
                    <th>WITH<br />Callie's Kitchen</th>
                    <th>WITHOUT<br />Callie's Kitchen</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='firstTD'>FULL Annual Schedule</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>
                <tr>
                    <td className='firstTD'>EARLY BIRD Registration</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>
                <tr>
                    <td className='firstTD'>Regular Social Media Posts</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td className='firstTD'>Family Stories</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>
                <tr>
                    <td className='firstTD'>Callie's Insights</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>
                <tr>
                    <td className='firstTD'>Giveaway PRIORITY</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>
                <tr>
                    <td className='firstTD'>Sponsor Discounts</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td className='firstTD'>ADDITIONAL Discounts</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>
                <tr>
                    <td className='firstTD'>The ''Inside Scoop''</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>
                <tr>
                    <td className='firstTD'>EXCLUSIVE Q&A Sessions</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>
                <tr>
                    <td className='firstTD'>Callie's Favorite Recipes</td>
                    <td>&#10004;</td>
                    <td></td>
                </tr>

            </tbody>
        </table>
    </div>
    )
}

export default KitchenTable