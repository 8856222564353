import './tour.css';
import tour from "../../webp/Callie_Tour_homepage.webp";
import { Outlet, Link } from "react-router-dom";

const Tour = (props) => {


return (
                <div style={{ display: props.display }}>
                    <div className='header-tour'>
                        <h1>PRO TOUR</h1>
                    </div>
                    <div className='tour-bg'>
                        <div className='tour-p'>
                            <p>As a Contract PPA Tour Athlete, Callie travels and competes in PPA & MLP events each year. Come and watch the multi-title PPA Champion battle all around the country!</p>
                            <Link to="/Sponsors"><button className='tour-button-two'>View Callie's Schedule</button></Link>
                        </div>
                        <div className='tour-boxes'>
                            <div className='tour-box'>
                                <img src={tour} className='tour-img' />
                            </div>
                            <div className='tour-box'>
                                <div className='pad-tour'>
                                    <h3>Promotional Discounts</h3>
                                    <p className='tour-par'>Callie takes very seriously the responsibility of representing brands that she believes in and believes will help those who follow her. As part of her sponsorship agreemetns, she does all she can to provide products to her followers for good deals and prices!</p>
                                    <Link to="/Sponsors"><button className='tour-button-two'>Use Callie's Sponsor Discounts</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
)

}

export default Tour