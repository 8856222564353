import './SponsorsIcons.css';
import React, { useState } from "react";
import SponIconOne from "../../images/LinkTree_Two.webp";
import SponIconTwo from "../../images/OnixIcon.webp";
import SponIconThree from "../../images/Optivida_NumberTwo.webp";
import SponIconFour from "../../images/Aloe_Md.webp";
import SponIconFive from "../../images/Hyundai.webp";
import SponIconSix from "../../images/ELEVEN.webp";
import SponIconSeven from "../../images/BZER.webp";
import SponIconEight from "../../images/headPickleballHead.png";
import Onix from './Onix';
import LinkTree from './LinkTree';
import Optiva from './Optiva';
//import Spry from './Spry';
import AloeMD from './AloeMd' ;
import Hyundai from './Hyundai' ;
import Head from './Head';


const SponsorIcons = () => {

    const [SponOne, setSponOne] = useState("block");
    const [SponTwo, setSponTwo] = useState("none");
    const [SponThree, setSponThree] = useState("none");
    const [SponFour, setSponFour] = useState("none");
    const [SponFive, setSponFive] = useState("none");
    const [SponSix, setSponSix] = useState("none");
    const [SponSeven, setSponSeven] = useState("none");
    const [SponEight, setSponEight] = useState("none");
 

    function handlerSponOne(){
        setSponOne('block');
        setSponTwo('none');
        setSponThree('none');
        setSponFour('none');
        setSponFive('none');
        setSponSix('none');
        setSponSeven('none');
        setSponEight('none');
    }

    function handlerSponTwo(){
        setSponOne('none');
        setSponTwo('block');
        setSponThree('none');
        setSponFour('none');
        setSponFive('none');
        setSponSix('none');
        setSponSeven('none');
        setSponEight('none');
    }

    function handlerSponThree(){
        setSponOne('none');
        setSponTwo('none');
        setSponThree('block');
        setSponFour('none');
        setSponFive('none');
        setSponSix('none');
        setSponSeven('none');
        setSponEight('none');
    }

    function handlerSponFour(){
        setSponOne('none');
        setSponTwo('none');
        setSponThree('none');
        setSponFour('block');
        setSponFive('none');
        setSponSix('none');
        setSponSeven('none');
        setSponEight('none');
    }

    function handlerSponFive(){
        setSponOne('none');
        setSponTwo('none');
        setSponThree('none');
        setSponFour('none');
        setSponFive('block');
        setSponSix('none');
        setSponSeven('none');
        setSponEight('none');
    }

    function handlerSponSix(){
        setSponOne('none');
        setSponTwo('none');
        setSponThree('none');
        setSponFour('none');
        setSponFive('none');
        setSponSix('block');
        setSponSeven('none');
        setSponEight('none');
    }

    function handlerSponSeven(){
        setSponOne('none');
        setSponTwo('none');
        setSponThree('none');
        setSponFour('none');
        setSponFive('none');
        setSponSix('none');
        setSponSeven('block');
        setSponEight('none');
    }

    
    function handlerSponEight(){
        setSponOne('none');
        setSponTwo('none');
        setSponThree('none');
        setSponFour('none');
        setSponFive('none');
        setSponSix('none');
        setSponSeven('none');
        setSponEight('block');
    }


    var SponsDesktop = 
        <div>
            <div className="Spon-Links">
                <div className="Spon-Icon">                    
                    <img src={SponIconOne} onClick={handlerSponOne} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponOne }}></div>
                </div>
                <div className="Spon-Icon">
                    <img src={SponIconEight} onClick={handlerSponEight} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponEight }}></div>
                </div>
                
                <div className="Spon-Icon">
                    <img src={SponIconThree} onClick={handlerSponThree} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponThree }}></div>
                </div>
                <div className="Spon-Icon">
                    <img src={SponIconFour} onClick={handlerSponFour} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponFour }}></div>
                </div> 
                
                        
            </div>
            <div className="boxes-Spon">
                <LinkTree display={SponOne}/>
                <Head display={SponEight} />
                <AloeMD display={SponFour} />
                {/*<Onix display={SponTwo}/>*/}
                <Optiva display={SponThree}/>
                
                
                
            </div>
            <div className="Spon-Links">
                <div className="Spon-Icon">
                    <img src={SponIconFive} className="hyundai" onClick={handlerSponFive} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponFive }}></div>
                </div>
           
                {/*<div className="Spon-Icon">
                    <img src={SponIconSix} onClick={handlerSponSix} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponSix }}></div>
                    </div>*/}
                {/*<div className="Spon-Icon">
                    <img src={SponIconSeven} onClick={handlerSponSeven} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponSeven }}></div>
                </div> */}
               
                   
            </div>
            <div className='boxes-Spon'>
                <Hyundai display={SponFive} />
                {/*<Eleven display={SponSix} />*/}
                {/*<Bzer display={SponSeven} />*/}
                
                {/*<Spry display={SponFive}/>   */} 
            </div>
            <br />
            <br />
        </div>;

    var SponsMobile = 
        <div>
            <div className="Spon-Links">
                    <div className="Spon-Icon">                    
                        <img src={SponIconOne} onClick={handlerSponOne} style={{ cursor: "pointer" }}/>
                        <div className="triangle" style={{ display: SponOne }}></div>
                    </div>
                        <div className="Spon-Icon">
                        <img src={SponIconEight} onClick={handlerSponEight} style={{ cursor: "pointer" }}/>
                        <div className="triangle" style={{ display: SponEight }}></div>
                    </div>
                    {/*<div className="Spon-Icon">
                        <img src={SponIconTwo} onClick={handlerSponTwo} style={{ cursor: "pointer" }}/>
                        <div className="triangle" style={{ display: SponTwo }}></div>
                    </div>
                */}
                 
            </div>
            <div className="boxes-Spon">
            <Head display={SponEight} />
                    <LinkTree display={SponOne}/>
                
                 {/*<Onix display={SponTwo}/>*/}
            </div>
            <div className="Spon-Links">
                <div className="Spon-Icon">
                        <img src={SponIconThree} onClick={handlerSponThree} style={{ cursor: "pointer" }}/>
                        <div className="triangle" style={{ display: SponThree }}></div>
                    </div>
                
                    <div className="Spon-Icon">
                        <img src={SponIconFour} onClick={handlerSponFour} style={{ cursor: "pointer" }}/>
                        <div className="triangle" style={{ display: SponFour }}></div>
                </div>
                
            </div>
            <div className="boxes-Spon">
                <Optiva display={SponThree}/>
                <AloeMD display={SponFour} />
            </div>
            <div className="Spon-Links">
                <div className="Spon-Icon">
                    <img src={SponIconFive} className="hyundai" onClick={handlerSponFive} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponFive }}></div>
                </div>
             {/* <div className="Spon-Icon ">
                    <img src={SponIconSeven} onClick={handlerSponSeven} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponSeven }}></div>
                </div>  */}
                {/*<div className="Spon-Icon moveInSpan">
                    <img src={SponIconSix} onClick={handlerSponSix} style={{ cursor: "pointer" }}/>
                    <div className="triangle" style={{ display: SponSix }}></div>
            </div>*/}
                  
            </div> 
            
            <div className='boxes-Spon'>  
                <Hyundai display={SponFive} />                  
                {/*<Bzer display={SponSeven} /> */}
                {/*<Eleven display={SponSix} /> */}          
            </div>
           
            <br />
            <br />
        </div>


return (
    <div>
        {window.innerWidth > 600 ?  SponsDesktop : SponsMobile}
    </div>    
)
}

export default SponsorIcons;