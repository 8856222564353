import './AloeMd.css';


const Head = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>HEAD Pickleball</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='onix-box'>
                    <h2>Founded in 1950, HEAD has been a titan in the world of sports for years. As a Tennis Player Callie used HEAD equipment exclusively, and she now does the same in pickleball. </h2>
                    <div className='onix-button'>
                    {/*<a href="https://www.aloemd.com/?ref=0NOcKe1Psbc64-" target="_blank" ><button className='clinic_button'>Shop AloeMD Products</button></a>*/}
                    <a href="https://linktr.ee/Calliejosmith" target="_blank"><button className='clinic_button'>Use Callie's Discount</button></a>
                    </div>                    
                </div>                         
            </div> 
            <br />      
        </div>
    )
}

export default Head;