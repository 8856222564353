import './Onix.css';

const Bzer = (props) => {
    var link = 'https://bzer.net/';
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>BZER</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='onix-box'>
                    <h2>BZER training tool provides players of EVERY level with the ability to improve hand-eye coordination and overall play. Get your BZER Training Tool to begin leveling up your game today! Use code "Callie10" for 10% off all purchases!</h2>
                    <div className='onix-button'>
                    <a href={link} target="_blank"><button className='clinic_button'>Shop BZER Products</button></a>
                    </div>                    
                </div>                         
            </div> 
            <br />      
        </div>
    )
}

export default Bzer;