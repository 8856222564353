import './Paddles.css';
import Scheduling from './Scheduling';
import kyleClinic from "../../images/Paddles_Image_One.webp";
import callieImg from "../../images/Paddles_Image_Two.webp";
import Emailmodal from "../Emailmodal_clinics.js";
import React, { useState, useEffect } from "react";
import axios from "axios";

var isMobile = "block";
var X = window.innerWidth;
if (X < 1000) {
    isMobile = "none";
}

const Paddles = (props) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        var url = "/Members/get_clinics.php";
        axios.post(url)
            .then(function (response) {
                var callie_ids = response.data;
                var callie = new Array();
                callie_ids.forEach(clinic => {
                    var dd = parseFloat(clinic.clinic_start_time);
                    if(dd > 11){
                        if(dd == 12){
                            dd.toString();
                            clinic.clinic_start_time = dd + ":00 pm";
                            
                        } else {
                            dd = dd - 12;
                            dd.toString();
                            clinic.clinic_start_time = dd + ":00 pm";
                        }
                    } else {
                        dd.toString();
                        clinic.clinic_start_time = dd + ":00 am";                        
                    }               
                    callie.push(
                        {
                            id: clinic.clinic_id,
                            name: clinic.clinic_name,
                            date: clinic.clinic_date,
                            time: clinic.clinic_start_time,
                            location: clinic.clinic_location,
                            link: clinic.clinic_link
                        }
                    )

                });
                setData(callie);
            }).catch((err) => {
                setError(err.message);
                setData(null);
            }).finally(() => {
                setLoading(false);
            });
    }, []);


    const [modalState_clinics, changeModal_clinics] = useState("none");

    function Listener_Modal_clinics() {
        if (modalState_clinics == "none") {
            changeModal_clinics("block");
        } else {
            changeModal_clinics("none");
        }
    }

    function closedModal_clinics(event) {
        if (event == document.querySelector('.modal-C')) {
            changeModal_clinics("none");
        }
    }


    return (
        <div style={{ display: props.display }}>
            <div className='top-header'><h1>CLINICS</h1></div>
            <div className='follow-bg'>
                <h1 className='middleHeader'>Clinics  with Callie near YOU:</h1>
                {loading && <div>A moment please...</div>}
                {data === null || (Array.isArray(data) && data.length === 0) ? (
                        <h2>New Clinics Coming Soon!</h2>
                    ) : (
                        Array.isArray(data) && data.map((schedule) => (
                            <Scheduling
                                key={schedule.id}
                                date={schedule.date}
                                time={schedule.time}
                                name={schedule.name}
                                location={schedule.location}
                                link={schedule.link}
                            />
                        ))
                    )}

                <div className='paddle-quote'>
                    <div className='clinic-p'>
                        <div className='border-line'>
                            <p className='clinic-quote'>''Callie and her team are SO encouraging, fun, insightful and dynamic. We loved every minute of it!! My husband came PURELY to support me, but after working with Callie decided that he wants to do another one!'' </p>
                            <h3>Kim Chapman</h3>
                        </div>
                    </div>

                </div>
                <div className='paddle-boxes'>
                    <div className='paddle-box'>
                        <div className='schedule-clinic'>
                            <h1>Schedule a New Clinic</h1>
                            <p>Callie is always looking to add new locations to her clinic schedule!</p>
                            <button onClick={Listener_Modal_clinics} className='clinic_button'>Contact Callie</button>
                            <Emailmodal callevent={closedModal_clinics} display={modalState_clinics} showmodal={Listener_Modal_clinics} />
                        </div>
                        <br />
                        <div>
                            <img src={kyleClinic} className='firstClincImg' />
                        </div>
                    </div>
                    <div className='paddle-box' style={{ display: isMobile }}>
                        <img src={callieImg} className='secondClincImg' />
                    </div>
                </div>

                <br />
            </div>
            <br />
        </div>
    );
}

export default Paddles;