import Carousel from 'react-bootstrap/Carousel';
import './Slider.css';
import { Outlet, Link } from "react-router-dom";
import Emailmodal from "../Emailmodal_nav.js";
import React, { useState } from "react";


const Slider = () => {

    const [modalState_home, changeModal_home] = useState("none");

    function Listener_Modal_Nav() {

        if (modalState_home == "none") {
            changeModal_home("block");
        } else {
            changeModal_home("none");
        }
        
    }

    function closed_Modal_Nav(event) {
        if (event == document.querySelector('.modal-Nav')) {
            changeModal_home("none");
        }
    }

    return (
        <>
        <Carousel>
            <Carousel.Item interval={2000}>
                <div className="back_one">
                    <div className="car_box">
                        <div className='textbox'><h1>Get Coaching From<br /><span className="title_one ">CALLIE</span></h1></div>
                        <Link to="/Coaching"><button className="car_button">Learn More!</button></Link>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <div className="back_two">
                    <div className="car_box">
                        <div className='textbox'><h1>Get Quick Tips &<br /><span className="title_one ">Tricks</span></h1></div>
                        <Link to="/SocialMedia"><button className="car_button">Follow Callie</button></Link>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <div className="back_three">
                    <div className="car_box">
                        <div className='textbox'><h1>Watch Callie Compete<br /><span className="title_one ">On Tour!</span></h1></div>
                        <Link to="/Coaching"><button className="car_button">Learn More!</button></Link>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <div className="back_six">
                    <div className="car_box">
                        <div className='textbox'><h1>Shop Callie <br /><span className="title_one ">Products</span></h1></div>
                        <a href="https://calliejosports.com/"><button className="car_button">Shop Now!</button></a>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <div className="back_five">
                    <div className="car_box">
                        <div className='textbox'><h1>Sponsor Callie<br /><span className="title_one ">Today!</span></h1></div>
                        <a onClick={Listener_Modal_Nav}><button className="car_button">Contact Us!</button></a>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>

        <Emailmodal callevent={closed_Modal_Nav} display={modalState_home} showmodal={Listener_Modal_Nav} />
        </>

    );
}

export default Slider