
import './Academy.css';
import academyOne from '../../images/AcademyImageOne.webp';

const Academy = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>

                <h1>PADDLE UP ACADEMY</h1>
                <div className='academy-boxes'>
                    <div className='academy-boxOne'>
                    <img src={academyOne} className='academyImageOne' />
                        
                    </div>
                    <div className='academy-boxTwo'>
                    <p className='academy-p border-line'>
                            ''Just finished with Callie and I am ALREADY seeing results. She emphasized areas that I wanted to see improvement in - and it is already helping my game.''
                        </p>
                        <h3>Carson Black</h3>
                    </div>
                </div>
            
            <br />
            </div>
            
            <div className='follow-bg'>
            <br />
            <br />
                <div className='fillerBox'>
                    <h1>Coming Soon!!</h1>
                </div>

            <br />
            <br />  
                
            </div>
            <br />
        </div>
    )
}

export default Academy;