
import './Analysis.css';
import analysisImage from '../../images/AnalysisImageOne.webp';


const Analysis = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>ANALYSIS WITH CALLIE</h1>
                <div className='analysis-boxes'>
                    <div className='analysis-boxOne'>
                        <p>Provide Callie with up to 45 minutes of video footage and get in-depth analysis of your personal gameplay and technique!</p>
                    </div>
                    <div className='analysis-boxTwo'>
                        <img src={analysisImage} className='analysisImageOne' />
                    </div>
                </div>
            
           
            </div>            
            <div className='online-bg'>
                <div className='Lists-Online'>
                    <h2>Gameplay Analysis Includes the following areas: </h2>
                    <ul className='listOnline'>
                        <li>Form & Technique of General Pickleball Shots</li>
                        <li>Footwork of General Pickleball Shots</li>
                        <li>Execution of Pickleball Strategy</li>
                    </ul>
                    <br />
                    <h2>Your Analysis Session with Callie Includes:</h2>
                    <ul className='listOnline'>
                        <li>15-45 Minutes of Video Analysis (15 Min MIN, 45 Min MAX)</li>
                        <li>Timestamped Voice Over Analysis with Callie</li>
                        <li>Statistical Analysis for Player</li>
                        <li>Email with a summary of Callie's Analysis</li>
                        <li>Paddle Up Academy Videos</li>
                    </ul>
                    <div style={{textAlign: "center"}}>
                    <button onClick={() => alert("Coming Soon!")} className='clinic_button'>Get Analysis from Callie</button>
                    
                    </div>
                    <br />
                </div>              
                
            </div>
            <br />
        </div>
    )
}

export default Analysis;